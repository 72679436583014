.scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }
  
  .scrollbar-hidden {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
.sidebar-transition {
  transition: transform 0.3s ease-in-out;
}

.hidden-sidebar {
  transform: translateX(-100%);
  /* display: none; */
}

.visible-sidebar {
  transform: translateX(0);
  display: block;
}
