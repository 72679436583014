.react-tel-input {
    margin-top:10px !important
}
.react-tel-input .form-control{

    width: 100% !important;
    outline: none !important;
    /* height: 40px !important; */
    border:none !important;
    border-radius:5 !important;

}
.flag-dropdown{
    background-color: white !important;;
    border:none !important
}
.pure-modal .close{
    display: none;
}
.pure-modal-backdrop .pure-modal{
    width:300px !important
    }

@media screen and(max-width:680px) {
    .pure-modal-backdrop .pure-modal{
    width:300px !important
    }
}