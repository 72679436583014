@tailwind base;
@tailwind components;
@tailwind utilities;
/* * {
	outline: purple 2px solid;
} */
:root {
	--background-color: #f9fafb;
}
@font-face {
	font-family: "Poppins";
	src: local("Poppins"),
		url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
}
body {
	margin: 0;
	padding: 0;
	min-height: 100vh;
	width: 100%;
	background-color: var(--background-color);
	font-family: "Poppins", sans-serif;
	overflow-x: hidden;
}

#root {
	width: 100%;
	height: 100%;
}

/* Custom progress bar color */
.Toastify__progress-bar {
	background-color: #9835d6 !important; /* Change this to your desired color */
}
/* Custom width for toast notifications */
.Toastify__toast {
	min-width: 500px !important; /* You can set this to any value you prefer */
}
